"use strict";

var $contactForm = $(".contact form");
var $inputs = $contactForm.find("input, button, textarea, .col-md-12");
var $msgHolder = $contactForm.find(".contact__bg__start");

function msg($holder, text) {
    var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};

    var $el = $("<div class=\"contact-msg\">\n                        <p>" + text + "</p>\n                        <button class=\"button\" type=\"button\">Zamknij</button>\n                    </div>");

    $holder.append($el);

    $el = $holder.find(".contact-msg");

    $el.find('button').click(function () {
        $el.remove();
        callback();
    });

    return $el;
};

function submitMsg(e) {
    e.preventDefault();

    var data = {};

    $contactForm.serializeArray().forEach(function (item) {
        data[item.name] = item.value;
    });

    $inputs.prop('disabled', true).css("opacity", "0");

    $.ajax({
        type: "POST",
        url: "mail.php",
        data: data,
        dataType: "json",
        encode: true
    }).done(function (data) {
        if (data.message) {
            msg($msgHolder, data.message, function () {
                $inputs.prop('disabled', false).css("opacity", "1");
            });
        }
        if (data.code == "send") {
            $contactForm.get(0).reset();
        }
    });
}

$contactForm.submit(submitMsg);