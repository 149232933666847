'use strict';

$(window).on('resize load', offsetMap);

var map = null;

function initMap() {
    google.maps.Map.prototype.offset = function (cords, offsetX, offsetY) {
        var map = this;
        var overlay = new google.maps.OverlayView();

        overlay.onAdd = function () {
            var projection = this.getProjection();
            var position = projection.fromLatLngToContainerPixel(cords);

            position.x = position.x + offsetX;
            position.y = position.y + offsetY - 140;

            map.panTo(projection.fromContainerPixelToLatLng(position));
        };

        overlay.draw = function () {};
        overlay.setMap(this);
    };

    var $map = $('.map').get(0);
    var cords = {
        lat: 53.358403,
        lng: 15.010137
    };

    map = new google.maps.Map($map, {
        zoom: 16,
        center: cords,
        disableDefaultUI: true
    });

    map.startCenter = map.center;

    var infowindow = new google.maps.InfoWindow({
        content: '\n            <div class="map__info">\n                <img src="assets/images/logo_black.svg" alt="Logo AW Biuro Rachunkowo-Us\u0142ugowe Agnieszka Wichmann" title="AW Biuro Rachunkowo-Us\u0142ugowe Agnieszka Wichmann" height="50">\n                <p>AW Biuro Rachunkowo-Us\u0142ugowe<br>Agnieszka Wichmann</p>\n                <p class="map__links">\n                    <a href="https://www.google.pl/maps/place/Podle%C5%9Bna+35,+73-110+Stargard/@53.3583969,15.0079319,17z/data=!3m1!4b1!4m5!3m4!1s0x4700b977079a0407:0x11b0501151742f94!8m2!3d53.358402!4d15.010136" target="_blank" title="Znajd\u017A nas!"><i class="fa fa-map-marker"></i>Podle\u015Bna 35, 73-110 Stargard</a>\n                    <br>\n                    <a href="tel:+48517503518" target="_blank" title="Zadzow\u0144!"><i class="fa fa-phone"></i>+48 517 503 518</a>\n                    <br>\n                    <a href="mailto:biuro@agnieszkawichmann.pl" target="_blank" title="Napisz do nas!"><i class="fa fa-envelope"></i>biuro@agnieszkawichmann.pl</a>\n                    <br>\n                    <a href="https://www.facebook.com/biuroagnieszkawichmann/" target="_blank"  title="Facebook @biuroagnieszkawichmann"><i class="fa fa-facebook-square" aria-hidden="true"></i>biuroagnieszkawichmann</a>\n                </p>\n            </div>\n        '
    });

    var marker = new google.maps.Marker({
        position: cords,
        map: map,
        icon: {
            url: 'assets/images/map-marker.png',
            anchor: new google.maps.Point(12.5, 12.5)
        }
    });

    marker.addListener('click', function () {
        infowindow.open(map, marker);
    });

    infowindow.open(map, marker);
    offsetMap();
};

function offsetMap() {
    var pageWidth = $('body').outerWidth();
    var offsetLeft = $('.contact__bg__start').offset().left;
    var contactOffsetLeft = $('.contact__container').offset().left;
    var offsetX = 0;

    if (offsetLeft - contactOffsetLeft != 0) {
        offsetX = pageWidth / 2 - offsetLeft - (contactOffsetLeft - offsetLeft) / 2;
    }

    map.offset(map.startCenter, offsetX, 0);
}