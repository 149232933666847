"use strict";

var $header = $("body > header");

$header.find(".hamburger").click(toggle);
$header.find(".page__nav a").click(close);

function close() {
    if ($header.find(".page__nav.open").length) {
        toggle.call($header.find(".hamburger").get(0));
    }
}

function toggle() {
    var $this = $(this);

    $this.toggleClass("open");
    $($this.data("toggle")).toggleClass("open");
    $(".page").toggleClass("menu");
}