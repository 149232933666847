"use strict";

var $contact = $(".contact");

$(window).on('resize', offsetBG);

$contact.find(".contact__bg").css('left', $contact.find(".contact__bg__start").offset().left);

function offsetBG() {
    $contact.find(".contact__bg").css('left', $contact.find(".contact__bg__start").offset().left);
}